import { Component, OnInit } from '@angular/core';
import { BaseService } from '../../services/BaseService';
import { FormControl, FormGroup, Validators } from '../../../node_modules/@angular/forms';
import { Router } from '@angular/router';
import { FormService } from '../../services/FormService';
import { DataEditService } from 'src/services/Data+EditService';
import { Ng2ImgMaxService } from 'ng2-img-max';

@Component({
  selector: 'app-customer-registration',
  templateUrl: './customer-registration.component.html',
  styleUrls: ['./customer-registration.component.css']
})
export class CustomerRegistrationComponent implements OnInit {

  customerFound: boolean;
  searchCustomerbtn:boolean = true
  customerData:any
  OTPSent: any = false;
  searchOTPSent: any = false;
  mobileNumber: FormControl
  mobile:FormControl
  otp: FormControl
  mobileNumberForm: FormGroup
  otpForm: FormGroup
  searchForm: FormGroup
  searchotpvalidateForm: FormGroup

  constructor(private _baseService: BaseService,private dataEditService:DataEditService, private router: Router, private formService:FormService, private ng2ImgMax: Ng2ImgMaxService) { }

  ngOnInit() {
    this.createForms();
    localStorage.removeItem("ROmobilenumber");
    localStorage.removeItem("ROcustomerid");
    localStorage.removeItem("loginsucessSessionID");
    localStorage.removeItem("ROtoken");
    localStorage.removeItem("CustomerData");
  }

  createForms() {
    this.mobileNumber = new FormControl('', [
      Validators.required,
      Validators.maxLength(10),
      Validators.minLength(10),
      Validators.pattern('^[6-9][0-9]{9}$')
    ]);
    this.mobile = new FormControl('', [
      Validators.required,
      Validators.maxLength(10),
      Validators.minLength(10),
      Validators.pattern('^[6-9][0-9]{9}$')
    ])
    this.mobileNumberForm = new FormGroup({
      mobileNumber: this.mobileNumber
    })

    this.otp = new FormControl('', [
      Validators.required
    ])

    this.searchForm = new FormGroup({
      mobile: this.mobile
    })

    this.searchotpvalidateForm = new FormGroup({
      otp: this.otp
    })

    this.otpForm = new FormGroup({
      otp: this.otp
    })

  }

  goToPage(pageName) {
    this.router.navigateByUrl(pageName);
  }

  ResendOTP(event) {
    this.issueOTPrequest();
    event.target.hidden = true
    setTimeout(() => {
      event.target.hidden = false
    }, 5000)
  }

  sendOrVerifyOTPSearch() {
    if (this.searchOTPSent) {
      if (this.searchotpvalidateForm.valid)
        this.verifyOTPRequest()
      else
        this.formService.validateAllFormFields(this.searchotpvalidateForm)
    }
    else {
      if (this.searchForm.valid)
        this.issueOTPrequest()
      else
        this.formService.validateAllFormFields(this.searchForm)
    }
  }

  issueOTPrequestSearch() {
    this._baseService.get('/generate/otp?mobile=' + this.mobile.value).subscribe((data: any) => {
      if (data.status.status) {
        this.searchOTPSent = true;
        this.customerFound = false;
        this.searchCustomerbtn = false
      }else{
        alert(data.status.message)
      }
    })
  }

  searchCustomer(){
    this.customerFound = false;
    this._baseService.get('customers/search?q=' + this.mobile.value).subscribe((data: any) => {
      if (data.status.status) {
        this.customerFound = true;
        this.router.navigateByUrl('/dashboard')
        this.customerData = data.customers[0]
        localStorage.setItem('CustomerData', this.customerData);
      } else {
        this.customerFound = false;
        alert(data.status.message);
      }
    })
  }

  sendOrVerifyOTP() {
    if (this.OTPSent) {
      if (this.otpForm.valid)
        this.verifyOTPRequest()
      else
        this.formService.validateAllFormFields(this.otpForm)
    }
    else {
      if (this.mobileNumberForm.valid){
        this.searchCustomerstatus()
      }
      else
        this.formService.validateAllFormFields(this.mobileNumberForm)
    }
  }

  issueOTPrequest() {
    this._baseService.get('/customers/issueotp?mobile=' + this.mobileNumber.value).subscribe((data: any) => {
      if (data.status.status) {
        this.OTPSent = true;
        this.customerFound = false;
        this.searchCustomerbtn = false
      }else{
        alert(data.status.message)
      }
    })
  }

  searchCustomerstatus(){
    this.customerFound = false;
    this._baseService.get('customers/search?q=91' + this.mobileNumber.value).subscribe((data: any) => {
      if(data.status.status == true){
        alert("Customer already exists with same mobile number")
        return false
      }else{
        this.issueOTPrequest()
      }
    })
  }

  verifyOTPRequest() {
    this._baseService.get('/customers/validateotp?mobile=' + this.mobileNumber.value + '&otp=' + this.otp.value).subscribe((data: any) => {
      if (data.status.status) {
       this.dataEditService.customer.mobile = this.mobileNumber.value;
       this.dataEditService.markStepComplete('/customer-registration')
        this.router.navigateByUrl('/business-details')
      }else{
        alert('Failed to validate OTP, Please use Resend option to send the OTP again')
      }
    })
  }

  changeNumber(){
    this.OTPSent = false
  }

  onlyten(event){
    if(event.target.value.length > 9){
      event.preventDefault();
    }
  }
}
