import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { MobilechangeComponent } from './search-customer/mobilechange/mobilechange.component';
import { StepperRightComponent } from './stepper-right/stepper-right.component';
import { HeaderComponent } from './header/header.component';
import { CustomerRegistrationComponent } from './customer-registration/customer-registration.component';
import { BaseService } from '../services/BaseService';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '../../node_modules/@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatProgressSpinnerModule, MatIconModule} from '@angular/material'
import { BusinessDetailsComponent } from './business-details/business-details.component';
import { LicenceDetailsComponent } from './licence-details/licence-details.component';
import { FormService } from '../services/FormService';
import { NgDatepickerModule } from 'ng2-datepicker';
import { BusinessAddressComponent } from './business-address/business-address.component';
import { PrimaryCardholderDetailsComponent } from './primary-cardholder-details/primary-cardholder-details.component';
import { AddOnDetailsComponent } from './add-on-details/add-on-details.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { DataEditService } from 'src/services/Data+EditService';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { TcComponent } from './tc/tc.component';
import { SearchCustomerComponent } from './search-customer/search-customer.component';
import { DashboardComponent } from './search-customer/dashboard/dashboard.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { WelcomeComponent } from './welcome/welcome.component';

@NgModule({
  declarations: [
    AppComponent,
    MobilechangeComponent,
    StepperRightComponent,
    HeaderComponent,
    SearchCustomerComponent,
    DashboardComponent,
    CustomerRegistrationComponent,
    BusinessDetailsComponent,
    LicenceDetailsComponent,
    BusinessAddressComponent,
    PrimaryCardholderDetailsComponent,
    AddOnDetailsComponent,
    UploadDocumentsComponent,
    TcComponent,
    WelcomeComponent,
    
   ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgDatepickerModule,
    Ng2ImgMaxModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NgxBarcodeModule,
    UiSwitchModule.forRoot({
      size: 'small',
      color: '#d52127',
      switchColor: '#FFF',
      defaultBgColor: '#ccc',
      checkedLabel: 'YES',
      uncheckedLabel: 'NO'
    })
  ],
  providers: [BaseService,FormService,DataEditService],
  bootstrap: [AppComponent],
  schemas:[NO_ERRORS_SCHEMA]
})
export class AppModule { }
