import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormService } from '../../services/FormService';
import { Router } from '@angular/router';
import { DataEditService } from 'src/services/Data+EditService';
import { BaseService } from 'src/services/BaseService';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
    selector: 'app-tc',
    templateUrl: './tc.component.html',
    styleUrls: ['./tc.component.css']
})
export class TcComponent implements OnInit {
    checkSelected: boolean = false
    constructor(private http: HttpClient, private formService: FormService, private _baseService: BaseService, private router: Router, private dataEditService: DataEditService) { }

    ngOnInit() {
    }
    isAnswerProvided(event, value) {
        console.log(event.target.checked)
        this.checkSelected = event.target.checked;
    }
    SubmitRegistration() {
        if (this.checkSelected) {
            fetch('assets/images/tandc.png')
                .then(res => res.blob()) // Gets the response and returns it as a blob
                .then(blob => {
                    var b: any = blob;
                    b.lastModifiedDate = new Date();
                    b.name = "tnc.png";
                    let file = <File>blob;
                    const formdata = new FormData();

                    formdata.append("file", file, 'tnc.png')

                    let httpOptions = {
                        headers: new HttpHeaders({ 'Authorization': 'Basic T25saW5lQ0FFMTpMb3RzRVJlZw==' })
                    };
                    this.http.post(this._baseService.baseUrl + "customers/" + this.dataEditService.customerId + "/upload?docId=" + this.dataEditService.concentUploadId, formdata, httpOptions).subscribe(((data: any) => {
                        this.dataEditService.RegistrationComplete.next(true);
                        setTimeout(() => {
                            window.location.href = "https://www.lotswholesale.com/"
                        }, 3000)
                        // if (data.status.status) {

                        // }
                        // else {
                        //   alert("Oops, Something went Wrong!")
                        // }
                    }))
                });

        } else {
            alert("Please Select the checkbox that You are agree to the above terms and conditions.")
        }
    }

    // goBack(){
    //   this.router.navigateByUrl('/upload-documents')
    // }
}
