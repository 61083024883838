import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BaseService } from '../../services/BaseService';
import { Router } from '@angular/router';
import { FormService } from '../../services/FormService';
import { DataEditService } from 'src/services/Data+EditService';
// import { stat } from 'fs';
// import { _createNgProbe } from '@angular/platform-browser/src/dom/debug/ng_probe';

@Component({
  selector: 'app-business-address',
  templateUrl: './business-address.component.html',
  styleUrls: ['./business-address.component.css']
})
export class BusinessAddressComponent implements OnInit {

  businessAddressForm: FormGroup;
  states: FormControl;
  plot: FormControl
  streetNumber: FormControl
  landmark: FormControl
  city: FormControl
  country: FormControl
  pincode: FormControl
  landline: FormControl

  _states = [];

  formCreated: boolean;
  constructor(private _baseService: BaseService, private dataEditService: DataEditService, private router: Router, private formService: FormService) { }

  ngOnInit() {
    this.getStates();
  }

  getStates() {
    this._baseService.get('/states').subscribe((data: any) => {
      if (data.status.status) {
        this._states = data.states;
        this._states = this._states.filter(item => item.name !== "EXPORT")
        this.createForm();
        this.SetRelevantState();
        this.states.setValue(this.dataEditService.additionalFormDetails.business_address ? this.dataEditService.additionalFormDetails.business_address.state : '')
      }
    })
  }

  SaveBusinessAddress() {
    if (this.businessAddressForm.valid) {
      this.SaveDataInService();
      this.dataEditService.markStepComplete('/business-address');
      this.router.navigateByUrl('/primary-cardholder-details');
    }
    else
      this.formService.validateAllFormFields(this.businessAddressForm)
  }

  createForm() {
    this.createControls();
    this.businessAddressForm = new FormGroup({
      plot: this.plot,
      streetNumber: this.streetNumber,
      city: this.city,
      states: this.states,
      country: this.country,
      pincode: this.pincode,
      landline: this.landline,
      landmark: this.landmark
    })
    this.formCreated = true
    this.fillformFields();
  }

  fillformFields(){
    this.plot.setValue(this.dataEditService.additionalFormDetails.business_address ? this.dataEditService.additionalFormDetails.business_address.plotNo : '')
    this.streetNumber.setValue(this.dataEditService.additionalFormDetails.business_address ? this.dataEditService.additionalFormDetails.business_address.street:'')
    this.city.setValue(this.dataEditService.additionalFormDetails.business_address ? this.dataEditService.additionalFormDetails.business_address.city : '')
    this.pincode.setValue(this.dataEditService.additionalFormDetails.business_address ? this.dataEditService.additionalFormDetails.business_address.pincode : '')
    this.landline.setValue(this.dataEditService.additionalFormDetails.business_address ? this.dataEditService.additionalFormDetails.business_address.landline : '')
    this.landmark.setValue(this.dataEditService.additionalFormDetails.business_address ? this.dataEditService.additionalFormDetails.business_address.landmark: '')
  }

  createControls() {
    this.plot = new FormControl('', Validators.required);
    this.streetNumber = new FormControl('', Validators.required);
    this.city = new FormControl('', Validators.required);
    this.states = new FormControl('', Validators.required);
    this.country = new FormControl({ disabled: true }, Validators.required);
    this.country.setValue("India");
    this.pincode = new FormControl('', [Validators.required,Validators.minLength(6),Validators.maxLength(6),Validators.pattern("^[1-9][0-9]{5}$")]);
    this.landline = new FormControl('',[Validators.maxLength(11),Validators.pattern("[0-9]+")]);
    this.landmark = new FormControl('');
  }

  SaveDataInService() {
    this.dataEditService.customer.addresses=[];
    let _customer = this.dataEditService.customer;
    let business_address = {
      "plotNo": "",
      "street": "",
      "city": "",
      "country": "",
      "state": "",
      "landLine": "",
      "landmark": "",
      "pincode": 0,
      "addressType": "BUSINESS",
      "addressTypeId": 1
    }

    let delivery_address = {
      "plotNo": "",
      "street": "",
      "city": "",
      "country": "",
      "state": "",
      "landLine": "",
      "landmark": "",
      "pincode": 0,
      "addressType": "DELIVERY",
      "addressTypeId": 2
    }

    business_address.plotNo = delivery_address.plotNo = this.plot.value
    business_address.street = delivery_address.street = this.streetNumber.value
    business_address.landmark = delivery_address.landmark = this.landmark.value
    business_address.city = delivery_address.city = this.city.value
    business_address.state = delivery_address.state = this.states.value
    business_address.country = delivery_address.country = this.country.value
    business_address.landLine = delivery_address.landLine = this.landline.value
    business_address.pincode = delivery_address.pincode = Number(this.pincode.value)
    _customer.addresses.push(business_address,delivery_address);
    this.SaveAdditionalFormDetails(business_address);
  }

  SaveAdditionalFormDetails(business_address){
    this.dataEditService.additionalFormDetails.business_address = business_address;
  }

  SetRelevantState() {
    if (this.dataEditService.customer.gstin != "/") {
      this._states = this._states.filter(state => {
        return this.dataEditService.customer.gstin.substr(0, 2) == state.tin
      })
    }
  }

}
