import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '../../../node_modules/@angular/router';
import { DataEditService } from 'src/services/Data+EditService';
import { PlatformLocation } from '@angular/common';


@Component({
  selector: 'app-stepper-right',
  templateUrl: './stepper-right.component.html',
  styleUrls: ['./stepper-right.component.css']
})
export class StepperRightComponent implements OnInit {

  activatedRoute: any = "";
  constructor(private router: Router, public dataEditService: DataEditService, private location: PlatformLocation) { }

  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof (NavigationStart)) {
        if (!event.url.includes('/upload-documents') ){
        this.dataEditService.markStepIncomplete(event.url)
        var LastAllowedStep = this.dataEditService.checkUrl(event.url);
          if (!LastAllowedStep) {
            this.router.navigateByUrl(this.dataEditService.lastStepCompleted)
            this.activatedRoute = this.dataEditService.lastStepCompleted
          }
          else {
            this.activatedRoute = event.url
          }
        }
      }
    })
  }

}
