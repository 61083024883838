import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class DataEditService {

    customerRegistrationComplete = false
    businessDetailsComplete = false
    licenceDetailsComplete = false
    businessAddressComplete = false
    primaryCardholderDetailsComplete = false
    addOnDetailsComplete = false
    uploadDocuments = false
    tc = false
    backbuttonatUploadClicked = false;

    RegistrationComplete = new BehaviorSubject(false);
    flowSelected = new BehaviorSubject(false);

    lastStepCompleted = "/customer-registration";

    constructor() {
    }

    addonDocuments ={
        documents :[]
    };
    additionalFormDetails:any={}

    concentUploadId = ""
    customerId = ""

    docTypes = [ {
        "id": 1,
        "name": "GST Registration",
        "type": "LICENCE"
    },
    {
        "id": 2,
        "name": "Trade License",
        "type": "LICENCE"
    },
    {
        "id": 3,
        "name": "Municipal Corp licenses",
        "type": "LICENCE"
    },
    {
        "id": 4,
        "name": "Certificate of Incorporation of Company or association",
        "type": "LICENCE"
    },
    {
        "id": 5,
        "name": "FSSAI License",
        "type": "LICENCE"
    },
    {
        "id": 6,
        "name": "Weight and Measurement License",
        "type": "LICENCE"
    },
    {
        "id": 7,
        "name": "Shops and Commercial Establishments Registration",
        "type": "LICENCE"
    },
    {
        "id": 8,
        "name": "Service Tax Registration",
        "type": "LICENCE"
    },
    {
        "id": 9,
        "name": "Registration under Societies act",
        "type": "LICENCE"
    },
    {
        "id": 10,
        "name": "CST Registration",
        "type": "LICENCE"
    },
    {
        "id": 11,
        "name": "Business PAN card",
        "type": "LICENCE"
    },
    {
        "id": 12,
        "name": "Excise License",
        "type": "LICENCE"
    },
    {
        "id": 13,
        "name": "Factory Registration",
        "type": "LICENCE"
    },
    {
        "id": 14,
        "name": "License to run educational institution",
        "type": "LICENCE"
    },
    {
        "id": 15,
        "name": "Charitable Welfare Trust Registration",
        "type": "LICENCE"
    },
    {
        "id": 16,
        "name": "Religious body NGO licenses",
        "type": "LICENCE"
    },
    {
        "id": 17,
        "name": "Labour License",
        "type": "LICENCE"
    },
    {
        "id": 18,
        "name": "APMC License",
        "type": "LICENCE"
    },
    {
        "id": 19,
        "name": "State Govt issued business license",
        "type": "LICENCE"
    },
    {
        "id": 20,
        "name": "Entertainment License",
        "type": "LICENCE"
    },
    {
        "id": 21,
        "name": "License under Essential Commodities Act",
        "type": "LICENCE"
    },
    {
        "id": 22,
        "name": "RBI Registration",
        "type": "LICENCE"
    },
    {
        "id": 23,
        "name": "Liquor License",
        "type": "LICENCE"
    },
    {
        "id": 24,
        "name": "Drug License",
        "type": "LICENCE"
    },
    {
        "id": 25,
        "name": "Kerosene Oil Outlet License",
        "type": "LICENCE"
    },
    {
        "id": 26,
        "name": "License under Essential Commodities Act",
        "type": "LICENCE"
    },
    {
        "id": 27,
        "name": "Authorization from Defense or Police or Army Regiment or Cantt. Areas",
        "type": "LICENCE"
    },
    {
        "id": 28,
        "name": "Indian Railways Suppliers License",
        "type": "LICENCE"
    },
    {
        "id": 29,
        "name": "Central Government Business Licenses",
        "type": "LICENCE"
    },
    {
        "id": 30,
        "name": "Electricity Board Contractor License",
        "type": "LICENCE"
    },
    {
        "id": 31,
        "name": "Fish Farm License",
        "type": "LICENCE"
    },
    {
        "id": 32,
        "name": "License from Dairy Development Department",
        "type": "LICENCE"
    },
    {
        "id": 33,
        "name": "Certificate of registration from CM & HO or CMO for Hospitals and Diagnostic Centres",
        "type": "LICENCE"
    },
    {
        "id": 34,
        "name": "Seed Business License",
        "type": "LICENCE"
    },
    {
        "id": 35,
        "name": "Professional Tax Registration",
        "type": "LICENCE"
    },
    {
        "id": 36,
        "name": "Government Fair Price shop",
        "type": "LICENCE"
    },
    {
        "id": 37,
        "name": "Gas Agency License",
        "type": "LICENCE"
    },
    {
        "id": 38,
        "name": "LOP for Export Oriented Units",
        "type": "LICENCE"
    },
    {
        "id": 39,
        "name": "Registered Partnership Deed",
        "type": "LICENCE"
    },
    {
        "id": 40,
        "name": "License for ensuring Polution Control",
        "type": "LICENCE"
    },
    {
        "id": 41,
        "name": "Registration for printing newspaper",
        "type": "LICENCE"
    },
    {
        "id": 42,
        "name": "License for cable TV operator",
        "type": "LICENCE"
    },
    {
        "id": 43,
        "name": "License from APEDA",
        "type": "LICENCE"
    },
    {
        "id": 44,
        "name": "Certificate of practice for CA",
        "type": "LICENCE"
    },
    {
        "id": 45,
        "name": "Registration under Central or State Medical or Dental Council (Private Clinics only)",
        "type": "LICENCE"
    },
    {
        "id": 46,
        "name": "Luxury Tax Registration",
        "type": "LICENCE"
    }]

    responseData:any = {
       
    }

    markStepComplete(url) {
        this.lastStepCompleted = url;

        switch (url) {
            case '/customer-registration': this.customerRegistrationComplete = true; break;
     
            case '/business-details': this.businessDetailsComplete = true; break;
            case '/licence-details': this.licenceDetailsComplete = true; break;
            case '/business-address': this.businessAddressComplete = true; break;
            case '/primary-cardholder-details': this.primaryCardholderDetailsComplete = true; break;
            case '/add-on-details': this.addOnDetailsComplete = true; break;
            case '/upload-documents': this.uploadDocuments = true; break;
            case '/T&C': this.tc = true; break;
        }

    }

    markStepIncomplete(url) {
        switch (url) {
            case '/customer-registration': this.customerRegistrationComplete = false; break;
            case '/business-details': this.businessDetailsComplete = false; break;
            case '/licence-details': this.licenceDetailsComplete = false; break;
            case '/business-address': this.businessAddressComplete = false; break;
            case '/primary-cardholder-details': this.primaryCardholderDetailsComplete = false; break;
            case '/add-on-details': this.addOnDetailsComplete = false; break;
            case '/upload-documents': this.uploadDocuments = false; break;
            case '/T&C': this.tc = false; break;
        }

    }

    checkUrl(url) {

        switch (url) {
            case '/customer-registration': return true;
            case '/dashboard': return true;
            case '/search': return true;
            case '/mobilechange': return true;
            case '/business-details': return this.businessDetailsComplete || this.customerRegistrationComplete;
            case '/licence-details': return this.licenceDetailsComplete || this.businessDetailsComplete;
            case '/business-address': return this.businessAddressComplete || this.licenceDetailsComplete;
            case '/primary-cardholder-details': return this.primaryCardholderDetailsComplete || this.businessAddressComplete;
            case '/add-on-details': return this.addOnDetailsComplete || this.primaryCardholderDetailsComplete;
            case '/upload-documents': return this.uploadDocuments || this.addOnDetailsComplete
            case '/T&C':return this.tc || this.uploadDocuments;
        }
    }

    getaddOnDocuments() {
        return this.addonDocuments.documents
    }

    customer = {
        "mobile": "",
        "firstName": "",
        "lastName": "",
        "gender": "",
        "email": "",
        "source": "web-selfreg",
        "dob": "",
        "preferredLanguage": "English",
        "segment": "",
        "segmentId": 0,
        "concentId": "",
        "businessLatitude": 0,
        "businessLongitude": 0,
        "annualTurnover": "",
        "gstin": "/",
        "customLegalName" : "",
        "pan": "",
        "registeredName": "",
        "registeredStore": "",
        "addOnsSelected" : false,
        "addresses": [

        ],
        "documents": [ {
            "docTypeId": 47,
            "name": "Government Photo ID",
        },
        {
            "docTypeId": 50,
            "name": "Person Photo",
        },
        {
            "docTypeId": 49,
            "name": "Shop/Establishment Photo",
        },
        {
            "docTypeId": 52,
            "name": "Consent Form",
        },
        {
            "docTypeId": 53,
            "name": "Other Documents",
        }],
        "addOnCustomers": [
        ]
    }

    customer_add_postbody = {
        "customers": [
            this.customer
        ]
    }

    clear(){
        this.customerRegistrationComplete = false
        this.businessDetailsComplete = false
        this.licenceDetailsComplete = false
        this.businessAddressComplete = false
        this.primaryCardholderDetailsComplete = false
        this.addOnDetailsComplete = false
        this.uploadDocuments = false
        this.tc = false
        this.RegistrationComplete.next(false);
    
        this.lastStepCompleted = "/customer-registration";

        this.customer = {
            "mobile": "",
            "firstName": "",
            "lastName": "",
            "gender": "",
            "email": "",
            "source": "web-selfreg",
            "dob": "",
            "preferredLanguage": "English",
            "segment": "",
            "segmentId": 0,
            "concentId": "",
            "businessLatitude": 0,
            "businessLongitude": 0,
            "annualTurnover": "",
            "gstin": "/",
            "customLegalName" : "",
            "pan": "",
            "registeredName": "",
            "registeredStore": "",
            "addOnsSelected" : false,
            "addresses": [
    
            ],
            "documents": [ {
                "docTypeId": 47,
                "name": "Government Photo ID",
            },
            {
                "docTypeId": 50,
                "name": "Person Photo",
            },
            {
                "docTypeId": 49,
                "name": "Shop/Establishment Photo",
            },
            {
                "docTypeId": 52,
                "name": "Consent Form",
            },
            {
                "docTypeId": 53,
                "name": "Other Documents",
            },],
            "addOnCustomers": [
            ]
        }
        
        this.additionalFormDetails= {};
        this.responseData = {}
    }

}
