import { Component, OnInit, SkipSelf } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormService } from '../../services/FormService';
import { Router } from '@angular/router';
import { DatepickerOptions } from 'ng2-datepicker';
import { DataEditService } from 'src/services/Data+EditService';
import { BaseService } from 'src/services/BaseService';

@Component({
  selector: 'app-add-on-details',
  templateUrl: './add-on-details.component.html',
  styleUrls: ['./add-on-details.component.css']
})
export class AddOnDetailsComponent implements OnInit {

  addOnSelected: boolean = false
  addOnNumberForm: FormGroup;
  numberofAddons: FormControl;
  addonForm: FormGroup;
  addOns = [];

  dobDateOptions: DatepickerOptions = {
    maxYear: new Date().getFullYear() + 1,
    maxDate: new Date(Date.now()),
    useEmptyBarTitle:false,
    barTitleFormat: 'MMMM YYYY'
  }

  constructor(private formService: FormService, private _baseService: BaseService, private router: Router, private dataEditService: DataEditService) { }

  ngOnInit() {
    if (this.dataEditService.backbuttonatUploadClicked)
    {
      this.dataEditService.markStepComplete('/add-on-details')
      this.router.navigateByUrl('/T&C');
    }
    this.createAddOnForm();
  }

  createAddOnForm() {
    this.numberofAddons = new FormControl();
    this.addOnNumberForm = new FormGroup({
      numberofAddons: this.numberofAddons
    })
    this.addonForm =  new FormGroup({});
    this.cleanAddonDocs();
    this.addOns=[];
    this.dataEditService.customer.addOnCustomers = []
    this.subsrcibeToAddOnNumberValueChanges();
    this.numberofAddons.setValue("1");
  }


  cleanAddonDocs(){
    var _looper = 0;
    this.dataEditService.addonDocuments.documents = []
    while(_looper!=-1)
    {
      var index = this.dataEditService.customer.documents.findIndex(doc=>doc.docTypeId == 48 || doc.docTypeId == 51 || doc.docTypeId == 54)
      if (index>=0)
      this.dataEditService.customer.documents.splice(index,1)
      
      _looper=index;
    }
  }
  subsrcibeToAddOnNumberValueChanges() {
    this.numberofAddons.valueChanges.subscribe(value => {

      var count = Number(value)
      var start = 1;
      if (this.addOns.length > count) {
        var length = this.addOns.length;
        
        for (var k = 0; k < ((length - count)); k++)
        {
        this.addOns.pop();
        this.removeFromForm(length-k)
      }
      return;
      }
      if (this.addOns.length < count) {
        start = this.addOns.length + 1
      }
      for (var i = start; i <= count; i++) {
        this.addToForm(i);
        this.addOns.push({ memberNumber: i })
      }
    })
  }
  removeFromForm(i){
 
    this.addonForm.removeControl('firstName-'+i)
    this.addonForm.removeControl('lastName-' + i )
    this.addonForm.removeControl('relation-' + i )
    this.addonForm.removeControl('landline-' + i )
    this.addonForm.removeControl('dob-' + i )
    this.addonForm.removeControl('mobile-' + i )
    this.addonForm.removeControl('email-' + i)

  }

  addToForm(number) {
 this.getFormControl(number)
  }

  getFormControl(number) {  
    let controls = {}
      let i = number;
      // fname: ['', [
      //   Validators.required,
      //   Validators.pattern(
      //     '^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_ \.]*$'
      //   )
      // ]],
      this.addonForm.addControl('firstName-'+i,new FormControl('', 
      [Validators.required,
      Validators.pattern('^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_ \.]*$')]))
      this.addonForm.addControl('lastName-' + i , new FormControl('',
      [Validators.pattern('^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_ \.]*$')]))
      this.addonForm.addControl('relation-' + i , new FormControl('', Validators.required))
      this.addonForm.addControl('landline-' + i , new FormControl())
      this.addonForm.addControl('dob-' + i , new FormControl())
      this.addonForm.addControl('mobile-' + i , new FormControl('', 
      [Validators.required, 
      Validators.maxLength(10),
      Validators.minLength(10),
      Validators.pattern('^[6-9][0-9]{9}$')]))
       this.addonForm.addControl('email-' + i, new FormControl('', Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$")))
    return controls
  }

  SaveOrSkipAddOn() {
    if (!this.addOnSelected) {
      this.skip();
    }
    else {
      if (this.addonForm.valid) {
        this.dataEditService.customer.addOnCustomers = [];
        this.dataEditService.addonDocuments.documents = [];
        for (var i = 1; i <= this.addOns.length; i++) {

          var date_of_birth = ""
          
          if (this.addonForm.get('dob-' + i).value) {
            var dob = new Date(this.addonForm.get('dob-' + i).value)
            date_of_birth = dob.getFullYear() + "-" + (dob.getMonth() + 1) + "-" + dob.getDate()
          }

          let addon = {
            "firstName": this.addonForm.get('firstName-' + i).value,
            "lastName": this.addonForm.get('lastName-' + i).value,
            "landline": this.addonForm.get('landline-' + i).value ? this.addonForm.get('landline-' + i).value : " ",
            "mobile": this.addonForm.get('mobile-' + i).value,
            "dob": date_of_birth,
            "relation": this.addonForm.get('relation-' + i).value,
            "email": this.addonForm.get('email-' + i).value,
            "serial": i
          }

          if(addon.email == ""){
            delete addon.email
          }

          this.dataEditService.customer.addOnCustomers.push(addon);
          
          var document_1 = {
            "docTypeId": 48,
            "name": "Add On Photo ID",
            "serial": i
          }

          var document_2 = {
            "docTypeId": 51,
            "name": "Add On Government Photo ID",
            "serial": i
          }

          var document_3 = {
            "docTypeId": 54,
            "name": "Add On Other Documents",
            "serial": i
          }

          this.dataEditService.addonDocuments.documents.push(document_1, document_2, document_3);
        }
        this.RegisterCustomer();
      }
      else {
        this.formService.validateAllFormFields(this.addonForm);
      }
    }
  }

  skip() {
    this.RegisterCustomer();
  }

  RegisterCustomer() {
    this.dataEditService.customer.documents.push(...this.dataEditService.getaddOnDocuments())
    this.dataEditService.markStepComplete('/add-on-details')
    this.router.navigateByUrl('/upload-documents')
  }
}
