import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { CustomerRegistrationComponent } from './customer-registration/customer-registration.component';
import { BusinessDetailsComponent } from './business-details/business-details.component';
import { LicenceDetailsComponent } from './licence-details/licence-details.component';
import { BusinessAddressComponent } from './business-address/business-address.component';
import { PrimaryCardholderDetailsComponent } from './primary-cardholder-details/primary-cardholder-details.component';
import { AddOnDetailsComponent } from './add-on-details/add-on-details.component';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import { TcComponent } from './tc/tc.component';
import { DashboardComponent } from './search-customer/dashboard/dashboard.component';
import { MobilechangeComponent } from './search-customer/mobilechange/mobilechange.component';
import { SearchCustomerComponent } from './search-customer/search-customer.component';

const routes: Routes = [
  { path: '', redirectTo: '/welcome', pathMatch: 'full' },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'customer-registration', component: CustomerRegistrationComponent },
  { path: 'business-details', component: BusinessDetailsComponent },
  { path: 'licence-details', component: LicenceDetailsComponent },
  { path: 'business-address', component: BusinessAddressComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'mobilechange', component: MobilechangeComponent },
  { path: 'search', component: SearchCustomerComponent },
  { path: 'primary-cardholder-details', component: PrimaryCardholderDetailsComponent },
  // { path: 'leaves', loadChildren: () => import(`./sub-header/sub-header-routing.module`).then(m => m.SecondRoutingModule) },
  { path: 'add-on-details', component: AddOnDetailsComponent },
  { path: 'T&C', component: TcComponent },
  { path: 'upload-documents', component: UploadDocumentsComponent },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes,{useHash:true})]
})
export class AppRoutingModule { }
