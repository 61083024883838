import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormControlDirective, Validators } from '@angular/forms';
import { BaseService } from '../../services/BaseService';
import { Router } from '@angular/router';
import { FormService } from '../../services/FormService';
import { DataEditService } from 'src/services/Data+EditService';
import { TouchSequence } from 'selenium-webdriver';

@Component({
  selector: 'app-business-details',
  templateUrl: './business-details.component.html',
  styleUrls: ['./business-details.component.css']
})
export class BusinessDetailsComponent implements OnInit {

  BusinessDetailsForm: FormGroup
  geolocation: FormControl
  consentId: FormControl
  store: FormControl
  businessRegistredName: FormControl
  email:FormControl
  mainCustomerGroup: FormControl
  customerGroup: FormControl
  subCustomerGroup: FormControl
  customerType: FormControl
  stores;
  _mainCustomerGroups = [];
  _customerGroups= [] ;
  _subCustomerGroups=[];
  _customerTypes=[];
  formCreated: boolean;
  isEmailDuplicate: boolean;
  selectedStoreAddress : any = ""
  states: FormControl;
  plot: FormControl
  streetNumber: FormControl
  landmark: FormControl
  city: FormControl
  country: FormControl
  pincode: FormControl
  landline: FormControl
  _states = [];

  customer= this.dataEditService.customer

  constructor(private _baseService: BaseService,private router:Router,private formService:FormService,private dataEditService:DataEditService) { }

  ngOnInit() {
    this.getStores();
    this.GetGeoLocation();
    this.getStates();
  }

  GetGeoLocation() {
    navigator.geolocation.getCurrentPosition((location) => {
      if(this.dataEditService.customer.businessLatitude.toString() == "0"){
        this.dataEditService.customer.businessLatitude  = location.coords.latitude
        this.dataEditService.customer.businessLongitude  = location.coords.longitude
        this.geolocation.setValue(location.coords.latitude + ", " + location.coords.longitude);  
      }
    }, (err: PositionError) => {
      if (err.code == 1)
        alert("You have denied permission to get your location, Please allow from browser settings if you change your mind")
      else
        alert(err.message)
    });
  }

  getStores() {
    this._baseService.get('stores').subscribe((data: any) => {
      if (data.status.status) {
        this.stores = data.stores
        this.getCustomerSegments();
      }
    })
  }

  getStates() {
    this._baseService.get('/states').subscribe((data: any) => {
      if (data.status.status) {
        this._states = data.states;
        this._states = this._states.filter(item => item.name !== "EXPORT")
        this.createForm();
        this.SetRelevantState();
        this.states.setValue(this.dataEditService.additionalFormDetails.business_address ? this.dataEditService.additionalFormDetails.business_address.state : '')
      }
    })
  }

  SetRelevantState() {
    if (this.dataEditService.customer.gstin != "/") {
      this._states = this._states.filter(state => {
        return this.dataEditService.customer.gstin.substr(0, 2) == state.tin
      })
    }
  }

  storeNameChanged(){
    this.stores.map(store => {
      if(store.externalId == this.store.value){
        this.selectedStoreAddress = store.address
        this.dataEditService.additionalFormDetails.storeAddress = store.address
      }
    })
  }

  getCustomerSegments() {
    this._baseService.get('customers/segments').subscribe((data: any) => {
      if (data.status.status) {
        this._mainCustomerGroups = data.segments
        this.createForm();
      }
    })
  }

  fillFormFields(){
    if (this.dataEditService.customer.businessLatitude !=0 && this.dataEditService.customer.businessLongitude != 0)
    this.geolocation.setValue(this.dataEditService.customer.businessLatitude +", "+this.dataEditService.customer.businessLongitude );
    this.email.setValue(this.dataEditService.customer.email);
    this.store.setValue(this.dataEditService.customer.registeredStore);
    this.states.setValue(this.dataEditService.customer.addresses[0].stateTin)
    this.businessRegistredName.setValue(this.dataEditService.customer.registeredName); 
    this.mainCustomerGroup.setValue(this.dataEditService.additionalFormDetails.mainCustomerGroup ? this.dataEditService.additionalFormDetails.mainCustomerGroup:'');
    this.customerGroup.setValue(this.dataEditService.additionalFormDetails.customerGroup? this.dataEditService.additionalFormDetails.customerGroup:'');
    this.subCustomerGroup.setValue(this.dataEditService.additionalFormDetails.subCustomerGroup? this.dataEditService.additionalFormDetails.subCustomerGroup:'');
    this.customerType.setValue(this.dataEditService.additionalFormDetails.customerType? this.dataEditService.additionalFormDetails.customerType:'');
    this.plot.setValue(this.dataEditService.additionalFormDetails.business_address ? this.dataEditService.additionalFormDetails.business_address.plotNo : '')
    this.streetNumber.setValue(this.dataEditService.additionalFormDetails.business_address ? this.dataEditService.additionalFormDetails.business_address.street:'')
    this.city.setValue(this.dataEditService.additionalFormDetails.business_address ? this.dataEditService.additionalFormDetails.business_address.city : '')
    this.pincode.setValue(this.dataEditService.additionalFormDetails.business_address ? this.dataEditService.additionalFormDetails.business_address.pincode : '')
    this.landline.setValue(this.dataEditService.additionalFormDetails.business_address ? this.dataEditService.additionalFormDetails.business_address.landLine : '')
    this.landmark.setValue(this.dataEditService.additionalFormDetails.business_address ? this.dataEditService.additionalFormDetails.business_address.landmark: '')
    this.selectedStoreAddress = this.dataEditService.additionalFormDetails.storeAddress ? this.dataEditService.additionalFormDetails.storeAddress: ''
  }

  createForm() {
    this.formCreated = true;
    this.createControls();
    this.BusinessDetailsForm = new FormGroup({
      geolocation: this.geolocation,
      store: this.store,
      businessRegistredName: this.businessRegistredName,
      email:this.email,
      mainCustomerGroup: this.mainCustomerGroup,
      customerGroup: this.customerGroup,
      subCustomerGroup: this.subCustomerGroup,
      customerType: this.customerType,
      plot: this.plot,
      streetNumber: this.streetNumber,
      city: this.city,
      states: this.states,
      country: this.country,
      pincode: this.pincode,
      landline: this.landline,
      landmark: this.landmark
    })

    this.fillFormFields()
  }

  createControls() {
    this.geolocation = new FormControl();
    this.store = new FormControl({}, Validators.required);
    this.store.setValue("");
    this.businessRegistredName = new FormControl('', [
      Validators.required
    ])
    this.mainCustomerGroup = new FormControl('', [
      Validators.required
    ])
    this.customerGroup = new FormControl('', [
      Validators.required
    ])
    this.subCustomerGroup = new FormControl('', [
      Validators.required
    ])
    this.customerType = new FormControl('', [
      Validators.required
    ])
    this.email = new FormControl('',[Validators.pattern("[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}")])
    this.setupNatureOfBusiness();
    this.plot = new FormControl('', Validators.required);
    this.streetNumber = new FormControl('');
    this.city = new FormControl('', Validators.required);
    this.states = new FormControl('', Validators.required);
    this.country = new FormControl({ disabled: true }, Validators.required);
    this.country.setValue("India");
    this.pincode = new FormControl('', [Validators.required,Validators.minLength(6),Validators.maxLength(6),Validators.pattern("^[1-9][0-9]{5}$")]);
    this.landline = new FormControl('',[Validators.maxLength(11),Validators.pattern("[0-9]+")]);
    this.landmark = new FormControl('');
  }

  setupNatureOfBusiness() {
    this.customerGroup.disable();
    this.subCustomerGroup.disable();
    this.customerType.disable();

    this.mainCustomerGroup.valueChanges.subscribe(value => {
      
      this.resetCustomerGroup();
      this.resetSubGroup();
      this.resetCustomerType();

      this._mainCustomerGroups.find((item: any) => {
        if (item.id == value) {
          this._customerGroups = item.subSegments;
          this.customerGroup.enable();
          return true;
        }
      })
    })

    this.customerGroup.valueChanges.subscribe(value => {

      this.resetSubGroup();
      this.resetCustomerType();

      this._customerGroups.find((item: any) => {
        if (item.id == value) {
          this._subCustomerGroups = item.subSegments;
          this.subCustomerGroup.enable();
          return true;
        }
      })
    })

    this.subCustomerGroup.valueChanges.subscribe(value => {
      this.resetCustomerType();
      this._subCustomerGroups.find((item: any) => {
        if (item.id == value) {
          this._customerTypes = item.subSegments;
          this.customerType.enable();
          return true;
        }
      })
    })
  }

  resetCustomerGroup(){
    this.customerGroup.reset();
    this.customerGroup.disable();
    this.customerGroup.setValue('');
    this._customerGroups = [];
  }

  resetSubGroup(){
    this.subCustomerGroup.reset();
    this.subCustomerGroup.disable();
    this.subCustomerGroup.setValue('');
    this._subCustomerGroups = [];
  }

  resetCustomerType(){
    this.customerType.reset();
    this.customerType.disable();
    this.customerType.setValue('');
    this._customerTypes = [];
  }

  SaveBusniessDetails(){
    var that = this;
    this.checkEmail().then(function(){
      if (that.BusinessDetailsForm.valid && !that.isEmailDuplicate)
      { 
        that.SaveDataInService();
        that.router.navigateByUrl('/primary-cardholder-details')
      }
      else
      that.formService.validateAllFormFields(that.BusinessDetailsForm);
    });
   
    
  }

  checkEmail(){
    let _this = this;
    return new Promise(function(resolve,reject){
      if(_this.email.value != ""){
        _this._baseService.get('customers/search?q='+_this.email.value+"&email=true").subscribe((data:any)=>{
          if (data.status.code == 1029)
          {
            resolve();
            _this.isEmailDuplicate = false
          }
          else if (data.customers.length > 0){
          resolve();
          _this.isEmailDuplicate = true
          }
        })
      }else{
        resolve();
        _this.isEmailDuplicate = false
      }
    });
  }

  SaveDataInService(){
   let geoLocation = this.geolocation.value.split(",")
   this.dataEditService.customer.businessLatitude = geoLocation[0] 
   this.dataEditService.customer.businessLongitude = geoLocation[1]
   this.customer.registeredStore = this.store.value
   this.customer.registeredName = this.businessRegistredName.value
   this.customer.segment = JSON.parse(this.customerType.value).name
   this.customer.segmentId = JSON.parse(this.customerType.value).id
   this.customer.email = this.email.value
   console.log("Email:Value",this.email.value);
   this.SaveAdditionalFormData();
   this.dataEditService.markStepComplete('/business-details')
   this.dataEditService.markStepComplete('/business-address');
   this.dataEditService.markStepComplete('/licence-details');
   this.dataEditService.customer.addresses=[];
    let _customer = this.dataEditService.customer;
    let business_address = {
      "plotNo": "",
      "street": "",
      "city": "",
      "country": "",
      "state": "",
      "stateTin" : "",
      "landLine": "",
      "landmark": "",
      "pincode": 0,
      "addressType": "BUSINESS",
      "addressTypeId": 1
    }

    let delivery_address = {
      "plotNo": "",
      "street": "",
      "city": "",
      "country": "",
      "state": "",
      "stateTin" : "",
      "landLine": "",
      "landmark": "",
      "pincode": 0,
      "addressType": "DELIVERY",
      "addressTypeId": 2
    }
    
    let statesFiltered = []
    this._states.map(state => {
      statesFiltered[state.tin] = ""
      statesFiltered[state.tin] = state.name
    })

    setTimeout(() => {
      business_address.plotNo = delivery_address.plotNo = this.plot.value
      business_address.street = delivery_address.street = this.streetNumber.value
      business_address.landmark = delivery_address.landmark = this.landmark.value
      business_address.city = delivery_address.city = this.city.value
      business_address.state = delivery_address.state = statesFiltered[this.states.value]
      business_address.stateTin = delivery_address.stateTin = this.states.value
      business_address.country = delivery_address.country = this.country.value
      business_address.landLine = delivery_address.landLine = this.landline.value
      business_address.pincode = delivery_address.pincode = Number(this.pincode.value)
      _customer.addresses.push(business_address,delivery_address);
      this.SaveAdditionalFormDetails(business_address);
    }, 200);
  }

  SaveAdditionalFormDetails(business_address){
    this.dataEditService.additionalFormDetails.business_address = business_address;
  }

  SaveAdditionalFormData(){

    this.dataEditService.additionalFormDetails.mainCustomerGroup = this.mainCustomerGroup.value;
    this.dataEditService.additionalFormDetails.customerGroup = this.customerGroup.value;
    this.dataEditService.additionalFormDetails.subCustomerGroup = this.subCustomerGroup.value;
    this.dataEditService.additionalFormDetails.customerType = this.customerType.value;
  }

  goBack(){
    this.router.navigateByUrl('/customer-registration')
  }
}

