import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  searchBtnenabled: boolean = false
  newCustomerbtn: boolean = true

  @Input() showSubHeader = true;
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    // this.router.events.subscribe((res) => {
    //   if (this.router.url == "/search" || "/dashboard") {
    //     this.searchBtnenabled = true
    //     this.newCustomerbtn = false
    //   }

    //   if (this.router.url == "/customer-registration" || "/business-details" || "/licence-details" || "/business-address" || "/primary-cardholder-details" || "/add-on-details" || "/upload-documents" || "/T&C") {
    //     this.searchBtnenabled = false
    //     this.newCustomerbtn = true
    //   }
    //   console.log(this.newCustomerbtn, "new cus");
    //   console.log(this.searchBtnenabled, "search cus");
    // })
  }

  goToPage(pageName) {
    this.router.navigateByUrl(pageName);
    if (pageName == "search") {
      this.newCustomerbtn = false
      localStorage.setItem("newCustomerbtn", 'false')
    } else {
      this.newCustomerbtn = true
      localStorage.setItem("newCustomerbtn", 'true')
    }
  }

  goToHomePage(){
    location.reload()
  }

}
