import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataEditService } from 'src/services/Data+EditService';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  searchBtnenabled: boolean = false
  newCustomerbtn: boolean = true

  constructor(
    private router: Router,
    private dataEditService: DataEditService
  ) { }

  ngOnInit() {
  }

  goToPage(pageName) {
    this.dataEditService.flowSelected.next(true)
    this.router.navigateByUrl(pageName);
  }

}
