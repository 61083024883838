import { Component, Input } from '@angular/core';
import { DataEditService } from 'src/services/Data+EditService';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  registerationComplete = false
  customer;
  @Input() showSubHeader = true;
  @Input() flowSelected = false

  constructor(private dataEditService: DataEditService, private router: Router) {
    this.dataEditService.RegistrationComplete.subscribe((value) => {
      if (value == true)
        this.registerationComplete = true
    })
    this.dataEditService.RegistrationComplete.subscribe((value) => {
      if (value == true)
        this.registerationComplete = true
    })
    this.dataEditService.flowSelected.subscribe((value) => {
      if(value == true)
        this.flowSelected = true
    })
    fromEvent(window, 'popstate')
      .subscribe((e: PopStateEvent) => {
        if (e.state) {
          if (e.state.navigationId && router.url.includes('/T&C') && !this.registerationComplete) {
            this.goBack();
          }
        }
      });
  }



  goBack() {
    var _route = this.router.url;
    if (_route.includes("/T&C")) {
      this.showConifrmationMessage();
    }
    else {
      window.history.back();
    }

  }

  showConifrmationMessage() {
    var sure = confirm("Your consent ID has already been generated, If you choose to go back now, You'll have register as a new customer with a new set of unique documents")
    if (sure) {
      this.dataEditService.clear();
      location.reload()
      // this.router.navigateByUrl('/customer-registration')
    }
    else {
      this.dataEditService.backbuttonatUploadClicked = true;
      // this.router.navigateByUrl('/T&C')
    }
  }
}
