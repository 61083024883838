import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataEditService } from 'src/services/Data+EditService';
import { FormService } from 'src/services/FormService';
import { BaseService } from 'src/services/BaseService';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-mobilechange',
  templateUrl: './mobilechange.component.html',
  styleUrls: ['./mobilechange.component.css']
})
export class MobilechangeComponent implements OnInit {

  customerFound: boolean;
  searchCustomerbtn: boolean = true
  customerData: any
  searchOTPSent: any = false;
  OTPSent: any = false;
  mobile: FormControl
  otp: FormControl
  searchForm: FormGroup
  otpvalidateForm: FormGroup
  userMobile = localStorage.getItem('ROmobilenumber');
  oldmobile: string;
  newmobile: string;
  customerbyCustomerID: any;
  brand = environment.brand
  countryCode = "91"
  updateidentiferbtnEnbable: boolean = false
  otpentered: boolean = false

  constructor(
    private _baseService: BaseService,
    private dataEditService: DataEditService,
    private router: Router,
    private formService: FormService
  ) { }

  ngOnInit() {
    this.createForms();
    this._baseService.get('customers/search?q=' + this.userMobile).subscribe((data: any) => {
      if (data.status.status) {
        this.customerFound = true;
        this.customerData = data.customers[0]
        this.oldmobile = this.customerData.mobile.slice(2);
        if (this.customerData.approvalStatus == "APPROVED") {
          this._baseService.getbyCustomerID('customers/' + this.customerData.id).subscribe((newdata: any) => {
            if (newdata.status.status) {
              this.customerbyCustomerID = newdata.customers[0]
            }
          })
        }
      } else {
        this.customerFound = false;
        alert(data.status.message);
        this.router.navigateByUrl('/search');
      }
    })
  }

  createForms() {
    this.mobile = new FormControl('', [
      Validators.required,
      Validators.maxLength(10),
      Validators.minLength(10),
      Validators.pattern('^[6-9][0-9]{9}$')
    ])
    this.searchForm = new FormGroup({
      mobile: this.mobile
    })

    this.otp = new FormControl('', [
      Validators.required
    ])

    this.otpvalidateForm = new FormGroup({
      otp: this.otp
    })
  }

  goToPage(pageName) {
    this.router.navigateByUrl(pageName);
  }

  // sendOrVerifyOTP() {
  //   if (this.searchOTPSent) {
  //     if (this.otpvalidateForm.valid)
  //       this.verifyOTPRequest()
  //     else
  //       this.formService.validateAllFormFields(this.otpvalidateForm)
  //   }
  //   else {
  //     if (this.searchForm.valid)
  //       this.issueOTPrequest()
  //     else
  //       this.formService.validateAllFormFields(this.searchForm)
  //   }
  // }

  newmobileOrVerifyOTP() {
    if (this.searchOTPSent) {
      if (this.otpvalidateForm.valid)
        this.verifynewmobileOTPRequest()
      else
        this.formService.validateAllFormFields(this.otpvalidateForm)
    }
    else {
      if (this.searchForm.valid)
        this.issuenewmobileOTPrequest()
      else
        this.formService.validateAllFormFields(this.searchForm)
    }
  }

  verifynewmobileOTPRequest() {
    let postBody = {
      mobile: 91 + this.mobile.value
    }
    this._baseService.get('/customers/validateotp?mobile=91' + this.mobile.value + '&otp=' + this.otp.value).subscribe((data: any) => {
      if (data.status.status) {
        this.updateidentiferbtnEnbable = true;
      } else {
        alert('Failed to validate OTP, Please use Resend option to send the OTP again')
      }
    })
  }

  issuenewmobileOTPrequest() {
    this.newmobile = this.mobile.value
    this._baseService.get('/customers/issueotp?mobile=' + this.mobile.value).subscribe((data: any) => {
      if (data.status.status) {
        this.searchOTPSent = true;
      } else {
        alert(data.status.message)
      }
    })
  }

  ResendOTP(event) {
    this.issuenewmobileOTPrequest();
    event.target.hidden = true
    alert('OTP issued successfully')
    setTimeout(() => {
      event.target.hidden = false
    }, 5000)
  }

  // issueOTPrequest() {
  //   let postBody = {
  //     mobile: 91 + this.mobile.value,
  //     brand: this.brand,
  //   }

  //   this.newmobile = this.mobile.value

  //   this._baseService.mobileApi('auth/v1/web/token/generate',postBody).subscribe((data: any) => {
  //     if (data.status.success == true) {
  //       let sessionId = data.user.sessionId
  //       let otpdata = {
  //         mobile: 91 + this.mobile.value,
  //         brand: this.brand,
  //         sessionId: sessionId
  //       }
  //       localStorage.setItem("loginsucessSessionID", data.user.sessionId)
  //       this._baseService.mobileApi('auth/v1/web/otp/generate',otpdata).subscribe((otpdata: any) => {
  //         if (otpdata.status.success == true) {
  //           this.searchOTPSent = true;
  //         }else {
  //           alert("something went wrong")
  //         }
  //       })
  //     }else {
  //       alert("something went wrong")
  //     }
  //   })
  // }

  // verifyOTPRequest() {
  //   let otpvalidatedata = {
  //     mobile: 91 + this.mobile.value,
  //     brand: this.brand,
  //     sessionId: localStorage.getItem('loginsucessSessionID'),
  //     otp: this.otp.value
  //   }
  //   this._baseService.mobileApi('auth/v1/web/otp/validate', otpvalidatedata).subscribe((otpvalidatedata: any) => {
  //     if (otpvalidatedata.status.success) {
  //         this.updateidentiferbtnEnbable = true;
  //         localStorage.setItem("ROtokennewmobile", otpvalidatedata.auth.token)
  //     }
  //     else {
  //       alert(otpvalidatedata.status.message)
  //     }
  //   })
  // }

  updateIdentifier(type) {
    let old_value, new_value, customer_id;

    let oldPhone = this.countryCode + localStorage.getItem('ROmobilenumber');
    let newPhone = this.countryCode + this.newmobile;

    let putBody = {
      "customers": [
        {
          "identifier": "MOBILE",
          "oldValue": this.countryCode + this.oldmobile,
          "newValue": this.countryCode + this.mobile.value,
          "otp": this.otp.value
        }
      ]

    }

    if (type == 'mobile') {
      customer_id = localStorage.getItem('ROcustomerid');
      this._baseService.mobilechangeput('customers/' + customer_id + '/changeidentifier', putBody).subscribe((data: any) => {
        if (data.status.status) {
          // alert(data.status.message);
          alert("Mobile number updated successfully");
          this.router.navigateByUrl('/search');
        } else {
          alert(data.status.message);
        }
      })
    }
  }

  onlyten(event){
    if(event.target.value.length > 9){
      event.preventDefault();
    }
  }

  otpchange(){
    this.otpentered = true
  }
}
